<template>
  <div>
      <p class="title">商户商品配置表</p>
    <div class="agent_search">
      <div style="padding-top:10px">
        <label class="lab" for="searchname">商户名称</label>
        <el-input clearable name="searchname" placeholder="请输入商品名称"  class="goods_search" v-model="goodsname"></el-input>
        <label class="lab" for="searchnum">商户号</label>
        <el-input clearable name="searchnum" placeholder="请输入商户号"  class="goods_search" v-model="shopsnum"></el-input>
        <label class="lab" for="searchnum">商户手机号</label>
        <el-input clearable name="searchnum" placeholder="请输入商户手机号"  class="goods_search" v-model="phone"></el-input>
        <Button type="primary" style="margin:0 5px 0 5px" @click="gettable">搜索</Button>
        <Button type="primary" @click="reset">重置</Button>
        </div>
    </div>
        <ul v-for="(item) in option" :key="item.id">
          <li @click="goodslist(item)">{{item.name}}</li>
        </ul>
  </div>
</template>

<script>
export default {
  inject:['chanlist'],
  data() {
    return {
      phone:"",
      goodsname:"",
      shopsnum:"",
      option:""
    }
  },
  mounted() {
    this.chanlist('1-3','1')
    this.gettable()
  },
  methods: {
    goodslist(item){
      this.$router.push({name:"Merchant_goods",params:{mid:item.id,name:item.name}})
    },
    reset(){
      this.goodsname=""
      this.shopsnum=""
      this.phone=''
      this.gettable()
    },
    gettable(){
      this.$axios.post("/supplier/merchant/list",{name:this.goodsname,account:this.shopsnum,phone:this.phone})
      .then((res)=>{
        if (res.data.code==20000) {
          this.option=res.data.data.rows
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title{height: 40px;line-height: 40px;padding-left: 20px;border-bottom: 1px solid gray;text-align: left;font-size: 20px;}
.agent_search{width:99%;height: 100px;border: 1px solid #ccc;margin-top: 10px;float: left;text-align: left;margin-left:10px ;}
.lab{font-size: 16px;margin:10px 10px 10px 20px;display: inline-block}
.goods_search{width: 220px;height: 25px;border-color: initial;margin-left: 3px}
ul{margin-top: 120px;}
li{float: left;height: 40px;display: block;cursor: pointer;line-height: 40px;font-size: 16px;font-family:"Microsoft Yahei", "Hiragino Sans GB", Arial, sans-serif;font-weight:400;margin:0 50px;width: 220px;margin-bottom: 50px;}
</style>